import { Common } from '../translations-types';

const translations: Common = {
  save: 'Save',
  submit: 'Submit',
  close: 'Close',
  email: 'Email',
  salon: 'Branch',
  salons: 'Branches',
  address: 'Address',
  city: 'City',
  dateOfBirth: 'Date of birth',
  fullName: 'Full name',
  gender: 'Gender',
  notes: 'Notes',
  phoneNumber: 'Phone number',
  alternatePhoneNumber: 'Alternative phone number',
  zipCode: 'Zip code',
  deleted: 'Deleted',
  displayName: 'Display name',
  password: 'Password',
  create: 'Create',
  includeDeleted: 'Include deleted',
  confirm: 'Confirm',
  update: 'Update',
  unknown: 'Unknown',
  description: 'Description',
  name: 'Name',
  firstName: 'First name',
  lastName: 'Last name',
  propertyName: 'Property name',
  value: 'Value',
  yes: 'Yes',
  no: 'No',
  unknownOption: 'Unknown option',
  toggle: 'Toggle',
  send: 'Send',
  unknownUser: 'Unknown user',
  user: 'User',
  actions: 'Actions',
  continue: 'Continue',
  edit: 'Edit',
  remove: 'Remove',
  type: 'Type',
  option: 'Option',
  cancel: 'Cancel',
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  complete: 'Complete',
  home: 'Home',
  delete: 'Delete',
  areYouSureYouWantToCancel: 'Are you sure you want to cancel?',
  changesWillBeDiscarded: 'All your changes will be discarded.',
  language: 'Language',
  search: 'Search',
  title: 'Title',
  changeImage: 'Change image',
  profileImage: 'Profile image',
  skip: 'Skip',
  reload: 'Reload',
  goToHomepage: 'Go to Homepage',
  add: 'Add',
  questionnaire: 'Questionnaire',
  question: 'Question',
  notSet: 'Not set',
  icon: 'Icon',
  month: 'Month',
  year: 'Year',
  days: 'Days',
  download: 'Download',
  whatsapp: 'Whatsapp',
  url: 'URL',
  text: 'Text',
  loading: 'Loading',
  agree: 'Agree',
  disagree: 'Disagree',
  translate: 'Translate',
  translations: 'Translations',
  next: 'Next',
  hidden: 'Hidden',
  or: 'Or',
  optionIsNotPurchased: 'Please upgrade your subscription to access this feature.',
  export: 'Export',
};

export default translations;
